@import 'src/scss/variables/maps';

.header {
    @apply py-4 sticky top-0 bg-white;
    z-index: map-get($map: $zIndex, $key: stickyHeader);
    nav {
        @apply flex items-center;
        [data-button] {
            display: inline-flex;
            width: auto;
        }
    }
}

.logo-centered {
    nav {
        @apply justify-center;
    }
}

.logo-and-cta {
    nav {
        @apply justify-between space-x-4 items-center;
    }
}

.header--dark {
    @apply bg-green-dark text-white;
}

.footer {
    @apply bg-green-dark text-white py-0;
}

.footer__mosh {
    @apply w-auto;
    @apply md:w-full;
}

.footer__menu {
    @apply flex space-x-4 text-sm justify-end;
    @apply md:w-full md:text-base;
}

.topbar {
    @apply flex justify-center items-center p-3 leading-6;
    @apply md:p-4;
    p { 
        @apply m-0 text-base lg:text-regular;
    }
    a { text-decoration: underline;}
}
