@import 'src/scss/utilities/breakpoints';

.wrapper {
    @apply ml-auto flex w-auto flex-nowrap items-center;
}

.links {
    @apply mr-4 hidden flex-nowrap items-center space-x-4 md:flex;
}

.navigation {
    @apply flex items-center;

    .navigation--container {
        @apply absolute right-0 top-0 w-0 transition-all;
        min-height: -webkit-fill-available;
        -webkit-overflow-scrolling: touch;
        height: 120vh;
        @include md-up {
            z-index: -1;
            pointer-events: none;
            top: var(--header-height);
            right: var(--header-navigation-offset);
            width: auto;
            @apply md:h-auto;
            transform: translateY(-100%);
            min-height: 0;
        }
        > * {
            @apply opacity-0 transition-opacity;
            //@apply md:opacity-100 transition-opacity;
        }
    }
    &[data-nav-open='true'] {
        .navigation--container {
            @apply w-[80%] p-4 md:p-0;
            > * {
                @apply opacity-100;
            }
            @include md-up {
                transform: none;
                right: var(--header-navigation-offset);
                transform: translateY(0);
                left: auto;
                width: auto;
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}
.navigation--container {
    @apply bg-sand-light shadow-md md:bg-white md:shadow-none;
}

.navigation--content {
    @apply overflow-y-auto pb-20;
    // You might be wondering how the hell we came to this solution, check this:
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    height: calc(var(--vh, 1vh) * 100 - var(--top-bar-height));
    @include md-up {
        @apply h-auto px-8 pb-0 pt-4;
    }
    [data-button] {
        @apply mx-0 max-w-[90%] md:z-10 md:max-w-none;
    }
}
.navigation--links {
    @apply py-2;
    li {
        @apply font-bolder uppercase tracking-[0.15rem];
        font-size: clamp(0.75rem, 1vw, 1rem);
        a {
            @apply block pb-6 hover:text-green;
        }
    }
}
