@import '../../scss/variables/colours';

.social-media-list {
    padding: 2rem 0;
    display: flex;
    align-content: center;
    align-items: center;
}
.links {
    width: 1.75rem;
    height: auto;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
    svg {
        width: 100%;
        height: auto;
        &:hover {
            color: $green;
        }
    }
}
