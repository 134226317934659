@import '../../scss/variables/_typography.scss';
@import '../../scss/utilities/mixins';
@import '../../scss/utilities/breakpoints';
@import 'src/scss/variables';

.accordion__button {
    display: flex;
    justify-content: space-between;
    font-size: $font-small;
    font-family: $font-maisonneue-book;
    align-items: center;
    width: 100%;
    cursor: pointer;
    min-height: 3.5rem;
    padding: 0.5rem 0;

    &.bolded {
        font-family: $font-maisonneue-demi;
    }

    [data-accordion-border='true'] & {
        @apply border-b border-grey-300;
    }
}

.accordion__title {
    display: flex;
    align-items: center;

    p {
        @apply mb-0 text-left;
    }

    .accordion__subtitle {
        display: block;
    }

    small {
        font-family: $font-family-main;
    }

    @include phone-only {
        padding-right: 1rem;
    }
}

.accordion__icon {
    margin-right: 1rem;
}

.accordion__content {
    transition: all 200ms;
    font-size: $font-regular;

    p,
    li {
        font-size: inherit;
        text-align: left;
    }

    a {
        font-size: $font-small;
        text-decoration: underline;
    }

    p {
        > a {
            font-size: $font-regular;
        }
    }

    ul {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 2rem;
        margin-bottom: 0.5rem;
    }

    li {
        display: list-item;
        line-height: 1.5;
        padding-bottom: 1rem;

        @include md-up {
            padding-bottom: 0.5rem;
        }
    }

    &.expanded {
        height: auto;
        padding: 1rem 0;
        opacity: 1;
    }

    &.collapsed {
        opacity: 1;
        height: 0;
        overflow: hidden;
        padding: 0;
    }

    a {
        &.link-small {
            font-size: $font-xsmall;
        }

        text-decoration: underline;
    }

    p {
        margin-bottom: 1rem;
    }
}

.accordion__column > div {
    @include md-up {
        margin-bottom: 2rem;
    }

    @include lg-up {
        margin-bottom: 1rem;
    }

    button {
        border-top: 2px solid transparent;
        border-bottom: 2px solid $green;
        padding-bottom: 0.5rem;
    }
}
