.hair-toggle {
    @apply flex flex-row flex-nowrap items-center justify-center space-x-4;
    > button {
        @apply h-full items-center rounded-lg py-2;
        svg {
            background: red;
        }
    }
}

.hair-toggle-compact {
    @apply flex flex-row flex-nowrap items-center justify-center space-x-2;
    > button {
        @apply flex h-full flex-row items-center justify-center rounded-lg p-2 text-center text-sm;
        svg {
            background: red;
        }
    }
}
