@import '../../scss/variables';
@import '../../scss/utilities/breakpoints';

$card-section-name-background: #f5f5f5;
$card-section-name-color: $green-dark;
$card-section-item-color: #6b6b6b;
$card-section-item-border-color: #e4e4e4;
$card-border-radius: 10px;

.card {
  @apply w-5/6 sm:w-4/6 lg:w-1/3 xl:w-1/4 snap-always snap-start drop-shadow;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  flex: 1 0 auto;
  scroll-snap-type: x mandatory;


  header {
      background-color: $card-section-name-background;
      border-radius: $card-border-radius $card-border-radius 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 3 / 2;
      img {
          object-fit: cover;
          height: 100%;
          width: 50%;

          &:first-child {
              border-radius: $card-border-radius 0 0 0;
          }
          &:last-child {
              border-radius: 0 $card-border-radius 0 0;
          }
      }
  }
  section {
      @apply bg-white px-4;
      border-radius: 0 0 $card-border-radius $card-border-radius;
      > div {
          @apply flex justify-between items-center py-2;
          border-bottom: 1px solid $card-section-item-border-color;
          &:first-child {
              background-color: $card-section-name-background;
              color: $card-section-name-color;
              @apply -mx-4 px-4;
          }
          &:last-child {
              border-bottom: 0;
          }
          > span {
              font-size: $font-xsmall;
              @include md-up {
                  font-size: $font-small;
              }
              &:first-child {
                  color: $card-section-item-color;
              }
              &:last-child {
                  text-align: right;
                  color: $card-section-item-color;
                  font-family: $font-family-bold;
              }
              &.name-age {
                  font-family: $font-family-bold;
                  font-size: $font-regular;
                  color: $green-dark;
              }
              &.state {
                  text-align: right;
                  font-family: $font-family-main;
              }
          }
      }
  }
}
