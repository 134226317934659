.text-wrapper {
  p {
    @apply text-center max-w-[110px] md:max-w-[180px] text-sm leading-4 tracking-[0.01rem];
    @apply md:text-[1.125rem] md:leading-6 md:tracking-normal;
  }
}

.trustpilot-wrapper {
  @apply flex items-center justify-center gap-1 mt-8;
  @apply md:mt-10;
  span {
    @apply mb-0 mt-1 text-xs leading-4 transition-all;
    @apply md:text-base;
  }
}
