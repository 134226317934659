.wrapper {
    @apply container;
    @apply lg:max-w-2xl lg:mx-auto;
    > :global(.heading) {
        @apply block w-full text-center mb-8;
    }
    .bottom {
        @apply block mb-8 text-center;
        p {
            @apply mb-2;
        }
    }
    .cta {
        @apply text-center;
    }
}

.cards {
    @apply flex flex-wrap mb-4;
    @apply sm:flex-nowrap sm:space-x-4;
    .card-pricing {
        @apply w-full mb-4;
        @apply sm:w-1/2;
        :global(.heading) {
            > * {
                @apply text-regular tracking-tight;
            }
        }
        section {
            > span {
                @apply text-grey-400 text-sm;
            }
        }
    }
}

.card--list {
    @apply flex flex-wrap py-2 border-y border-y-grey-200 my-2;
    li {
        @apply w-full flex flex-nowrap justify-between items-center text-sm;
    }
    .value {
        @apply font-demi;
    }
}

.card--pricing {
    .prefix,
    .value {
        @apply text-regular font-demi tracking-tight;
    }
    .frequency {
        @apply pl-1 text-sm;
    }
}

.cta {
    [data-button] {
        @apply mb-0;
    }
}