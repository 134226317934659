@import '/node_modules/swiper/swiper.min.css';

.comparison-cards {
    @apply flex flex-wrap;
    :global(.swiper-pagination) {
        @apply order-first relative top-auto bottom-auto;
        @apply static top-auto right-auto bottom-auto left-auto order-first mb-4;
        @apply flex w-auto mx-auto flex-nowrap;
        @apply bg-green-light lg:hidden;
        border-radius: 6rem;
        width: auto !important;
        :global(.swiper-pagination-bullet) {
            @apply w-auto h-auto m-0 text-sm py-4 px-6 text-green-dark opacity-100;
            @apply md:py-4 md:px-8;
            background-color: transparent;
            border-radius: 6rem;
        }
        :global(.swiper-pagination-bullet-active) {
            @apply bg-green-dark text-white font-bolder;
        }
    }
    :global(.swiper-wrapper) {
        @apply items-center;
        :global(.swiper-slide) {
        }
    }
}

$card-height: 600px;

.comparison-cards--card {
    @apply px-4;
    > div {
        height: calc(var(--card-height) - 2rem);
        @apply border-2 p-4 rounded-xl;
    }
    &[data-card-featured='true'] {
        @apply px-0 pt-0;

        > div {
            height: auto;
            @apply bg-green-dark text-white;
        }
    }
}

.comparison-cards--card--heading {
    @apply mb-2;
    > * {
        @apply text-md;
    }
}

.comparison-cards--card--includes {
    @apply mb-4;
    [data-card-featured='true'] & {
        @apply text-green-light;
    }
}

.comparison-cards--card--price {
    @apply text-md font-bolder mb-2;
}

.comparison-cards--card--content {
    @apply border-t border-t-sand pt-4;
}

.comparison-cards--card--featured {
    @apply border-opacity-0;
}
