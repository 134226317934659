@import "../../src/scss/variables";

.blog-content {
    @apply px-4;
    a {
        text-decoration: underline;
        font-family: $font-maisonneue-demi;
    }

    ul {
        list-style: disc;
        list-style-position: inside;
        padding-left: 1rem;
    }
    p {
        margin-top: 1rem;
    }
}

.blog-content__header {
    @apply  mt-6 mb-4;

    h2 {
        scroll-margin: 5rem;
    }
}

.blog-content__img {
    @apply relative h-[12.5rem] w-full md:h-[22.5rem] rounded-2xl mt-4;
   
    img {
        object-fit: cover;
        border-radius: 1rem;
    }
}
