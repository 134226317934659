@import '../../../scss/variables/colours';
@import '../../../scss/utilities/breakpoints';

$header-background: $white;

.header {
    position: relative;
    z-index: 100;
    background-color: $header-background;
    .wrapper {
        position: relative;
        height: var(--header-height);
    }

    .header--navigation {
        &:global(.headroom--pinned.headroom--not-top) {
            position: fixed;
            transform: translateY(0);
        }
        &:global(.headroom--unpinned) {
            position: relative;
            transform: translateY(-100%);
        }
    }
}
