@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables';

.doctors {
    @apply  max-w-[50rem] p-4 md:m-auto md:px-0;
}

.doctors-info {
    background-color: $sand-light;

    @include md-up {
        background-color: $white;
        display: flex;
        border-radius: 16px;
        padding: 16px;
    }
 }

.doctors-card {
    @apply flex flex-row md:flex-col;
    background-color: $white;
    width: 100%;
    min-height: 96px;
    border-radius: 16px;
   // margin-top: 24px;

    @include md-up {
        min-height: unset;
        border-right: 1px solid $grey-200;
        border-radius: 0;
        margin-top: 0;
        margin-right: 24px;
        justify-content: center;
    }
}

.doctors-card__info {
    @apply flex flex-col justify-center;
    margin-left: 16px;

    @include md-up {
        margin-left: 0;
        margin-top: 16px;
    }
}

.doctors-card__name {
    font-size: 18px;
    font-family: $font-maisonneue-demi;
}

.doctors-card__img {
    @apply relative;
    height: inherit;
    width: 80px;

    img {
        object-fit: cover;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }

    @include md-up {
        height: 104px;
        width: 131px;

        img {
            border-radius: 16px;
        }
    }
}

.doctors-card__bio {
    margin-top: 24px;

    p:not(:first-child) {
        margin-top: 24px;
    }

    @include md-up {
        margin-top: 0;
    }

    a {
        text-decoration: underline;
        font-family: $font-maisonneue-demi;
    }
}
