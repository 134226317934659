@import 'maps';

// Colours
$green-dark: map-get(
    $map: $colors,
    $key: green-dark,
);
$green-faint-dark: map-get(
    $map: $colors,
    $key: green-faint-dark,
);
$green-faint-light: map-get(
    $map: $colors,
    $key: green-faint-light,
);
$green: map-get(
    $map: $colors,
    $key: green,
);
$green-50: map-get(
    $map: $colors,
    $key: green-50,
);
$green-med: map-get(
    $map: $colors,
    $key: green-med,
);
$green-light: map-get(
    $map: $colors,
    $key: green-light,
);
$green-xlight: map-get(
    $map: $colors,
    $key: green-xlight,
);
$green-pale: map-get(
    $map: $colors,
    $key: green-pale,
);
$orange: map-get(
    $map: $colors,
    $key: orange,
);
$orange-dark: map-get(
    $map: $colors,
    $key: orange-dark,
);
$sand: map-get(
    $map: $colors,
    $key: sand,
);
$sand-light: map-get(
    $map: $colors,
    $key: sand-light,
);
$sand-xlight: map-get(
    $map: $colors,
    $key: sand-xlight,
);

$blue: map-get(
    $map: $colors,
    $key: blue,
);
$blue-dark: map-get(
    $map: $colors,
    $key: blue-dark,
);
$blue-light: map-get(
    $map: $colors,
    $key: blue-light,
);
$blue-extra-light: map-get(
    $map: $colors,
    $key: blue-extra-light,
);

$purple: map-get(
    $map: $colors,
    $key: purple,
);
$purple-dark: map-get(
    $map: $colors,
    $key: purple-dark,
);
$purple-light: map-get(
    $map: $colors,
    $key: purple-light,
);
$purple-extra-light: map-get(
    $map: $colors,
    $key: purple-extra-light,
);

$yellow: map-get(
    $map: $colors,
    $key: yellow,
);
$yellow-dark: map-get(
    $map: $colors,
    $key: #fdbd24,
);
$white: map-get(
    $map: $colors,
    $key: white,
);
$grey: map-get(
    $map: $colors,
    $key: grey,
);
$grey-dark: map-get(
    $map: $colors,
    $key: grey-dark,
);
$grey-light: map-get(
    $map: $colors,
    $key: grey-200,
);
$black: map-get(
    $map: $colors,
    $key: black,
);
$colour-primary: $green;
$error-red: #df2e2e;
$red-error: map-get(
    $map: $colors,
    $key: red-error,
);

$grey-100: #f6f6f6;
$grey-200: #e2e2e2;
$grey-300: #cbcbcb;
$grey-400: #6b6b6b;

$livegreen: #69c97c;
$progressgreen: #39ce8f;
