@import '../../scss/variables/index';
@import '../../scss/utilities/breakpoints';
@import '../../scss/variables/components';

.toggleButton {
    @apply flex flex-col items-center;
    font-family: $font-clearface-bold;
    color: $green-dark;
    border-radius: $border-radius;
    height: 5.5rem;
    width: 5.5rem;
    background-color: $white;

    &.active {
        color: $white;
        background-color: $green-dark;
    }

    @include md-up {
        flex-direction: row;
        justify-content: initial;
        width: 18.75rem;
        height: 4rem;
        padding: 1.25rem;
        font-size: $font-medium;
    }

    @include ipad-portrait-only {
        width: calc(100% / 3);
        height: auto;
        padding: 1rem;
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }

    @include ipad-landscape-only {
        width: calc(100% / 3);
        height: auto;
        padding: 1rem;
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
}

.toggleButton__img {
    width: 2.5rem;

    @include md-up {
        margin-right: 1.25rem;
    }
}
