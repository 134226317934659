@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.messagedoctor {
    width: 280px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @include sm-up {
        width: 390px;
    }

    @include lg-up {
        width: 450px;
    }

    @include xl-up {
        margin-top: -2rem;
    }
}

.messagedoctor--mobilescreen {
    width: 100%;
    height: 100%;
    padding: 2rem 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .message-screen-chat,
    .message-screen-header {
        width: 100%;
        flex-shrink: 0;
    }

    .message-screen-header {
        flex-grow: 0;
    }

    .message-screen-chat {
        flex-shrink: 0;
        // height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        align-content: flex-end;
        padding-bottom: 1rem;
    }
}

.message-screen-header {
    figure {
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 60px;
    }
}

.message-doctor-info {
    padding: 1rem 2rem;
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 3%);

    .doctor-name {
        color: $green-dark;
        font-family: $font-family-bold;

        @include lg-up {
            font-size: $font-medium;
        }
    }

    .usually-responds {
        color: $green;
        font-weight: bold;
        font-size: $font-xsmall;

        @include lg-up {
            font-size: $font-regular;
        }
    }

    .message-doctor-info-more {
        flex-shrink: 0;
        margin-left: auto;
    }
}

.message-screen-chat {
    padding: 1rem 2rem;
    overflow-y: hidden;

    @include lg-up {
        padding: 1rem 3rem;
    }
}

.message-screen-chat--wrapper {
    transform: translateY(100%);
    animation: scrollMessagesXs 8s infinite;

    @include sm-up {
        animation: scrollMessagesMd 8s infinite;
    }

    @include lg-up {
        animation: scrollMessagesLg 8s infinite;
    }
}

// Phone Frame Message animations

@keyframes scrollMessagesXs {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }

    25% {
        transform: translateY(18%);
        opacity: 1;
    }

    50% {
        transform: translateY(-9%);
        opacity: 1;
    }

    75% {
        transform: translateY(-41%);
        opacity: 1;
    }

    98% {
        opacity: 1;
    }

    99% {
        opacity: 0.8;
    }

    100% {
        transform: translateY(-63%);
        opacity: 0;
    }
}

@keyframes scrollMessagesMd {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }

    25% {
        transform: translateY(57%);
        opacity: 1;
    }

    50% {
        transform: translateY(33%);
        opacity: 1;
    }

    75% {
        transform: translateY(3%);
        opacity: 1;
    }

    98% {
        opacity: 1;
    }

    99% {
        opacity: 0.8;
    }

    100% {
        transform: translateY(-20%);
        opacity: 0;
    }
}

@keyframes scrollMessagesLg {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }

    25% {
        transform: translateY(73%);
        opacity: 1;
    }

    50% {
        transform: translateY(50%);
        opacity: 1;
    }

    75% {
        transform: translateY(20%);
        opacity: 1;
    }

    98% {
        opacity: 1;
    }

    99% {
        opacity: 0.8;
    }

    100% {
        transform: translateY(-8%);
        opacity: 0;
    }
}

.single-message {
    color: $black;
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    transition: all 200ms;

    .single-message-content {
        display: flex;
        flex-direction: row-reverse;

        img {
            width: 24px;
            height: 24px;
            margin-left: 0.5rem;
        }
    }

    &:nth-of-type(2n) {
        img {
            width: 32px;
            height: 32px;
            margin-right: 0.5rem;
        }

        .single-message-content {
            flex-direction: row;
        }

        .message-content {
            margin-left: 8px;
            margin-right: 0;
            background-color: $grey-200;
            border-radius: 1rem 1rem 1rem 0;
        }
    }

    .message-content {
        padding: 0.75rem;
        width: 100%;
        background-color: $green-light;
        border-radius: 1rem 1rem 0 1rem;

        .typing-text {
            display: none;

            .is-typing & {
                display: block;

                +.message-text {
                    display: none;
                }
            }
        }

        p {
            color: $black;
            font-size: $font-small;
        }

        .message-time {
            text-align: right;
            color: $black;
            display: block;
            opacity: 60%;
            font-size: $font-xsmall;
        }
    }
}
