.default {
    background-color: inherit;
    width: 100%;
    min-height: 6rem;
    position: relative;

    > div {
        padding: 3rem 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        svg {
            width: 3rem;
            height: 3rem;
        }
    }
}

.full-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
}
