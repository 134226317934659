@import '../../src/scss/variables';

.hairlines {
    padding: 3rem 0;
    @apply bg-green-light py-8;
    @apply md:px-0;
    @apply lg:py-16;
    [data-heading] {
        @apply mx-auto mb-12;
        @apply lg:mx-0;
    }
    ::-webkit-scrollbar {
        cursor: pointer;
        border: 1px solid transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 1rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $green;
        border-radius: 0.5rem;

        &:hover {
            background: $green-dark;
        }
    }
}
