@import 'src/scss/variables';

.wrapper {
    max-width: 300px;
}
.ring {
    text {
        text-anchor: middle;
        dominant-baseline: middle;
        font-family: $font-family-headings;
        font-size: $font-medium;
        fill: $green-dark;
    }
}
.circle,
.stroke {
    stroke-width: 20px;
}

.circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    fill: transparent;
    stroke: $orange;
}

.stroke {
    stroke: $green-light;
}

.bar {
    width: 100%;
    height: 3.5rem;
    border-radius: 5px;
    border: 1px solid $green;
    background: $sand-light;
    position: relative;
    span {
        position: absolute;
        top: 50%;
        left: 0;
        color: $sand-light;
        transform: translateY(-50%);
        text-align: center;
        letter-spacing: 1px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: $green;
        border: 1px solid;
    }
    progress {
        opacity: 0; // This will be removed once it's compatible with all versions of iOS.
    }
    progress[value] {
        width: 100%;
        height: 100%;
        border: 0;
        &::-webkit-progress-bar {
            background: transparent;
        }
        &::-webkit-progress-value {
            background: $green;
        }
        &::-moz-progress-bar {
            background: $green;
        }
    }
}

.vertical-progress-bar-wrap {
    @apply relative h-[15rem] md:h-[20rem] w-[10rem] flex justify-center items-center;
    max-width: clamp(8rem, 40vw, 10rem);
}

.vertical-progress-bar {
    @apply h-[10rem] -rotate-90 appearance-none rounded-r-md w-[15rem] md:w-[20rem] absolute;
    max-height: clamp(8rem, 40vw, 160px);

    &::-moz-progress-bar { 
        @apply rounded-r-md;

        [data-variant="light"] & {
            @apply bg-green;
        }

        [data-variant="dark"] & {
            @apply bg-green-dark
        }
    }

    &::-webkit-progress-value { 
        @apply rounded-r-md;

        [data-variant="light"] & {
            @apply bg-green;
        }

        [data-variant="dark"] & {
            @apply bg-green-dark
        }
    }

    &::-webkit-progress-value { 
        @apply rounded-r-md;
    }

    &::-webkit-progress-bar {
        @apply bg-transparent;
    }
}

.vertical-progress-bar-content {
    @apply absolute px-2 text-center left-1/2 -translate-x-1/2 w-full;
    top: calc((100% - var(--vertical-progress-bar-value)) + 1rem);

    [data-variant="light"] & {
        @apply text-green-dark;
    }

    [data-variant="dark"] & {
        @apply text-white;
    }
    
    span {
        @apply block;
    }
}