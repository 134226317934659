.cell {
    @apply text-center border-b border-green-dark px-1 items-center justify-center;
}

.header-cell {
    @apply py-4;
    @apply md:px-4;
}

.column-cell {
    @apply py-6;
    @apply md:px-8;
}

.collapsed-cell {
    &.collapsed {
        display: none;
    }
    &.expanded {
        @apply block text-xs leading-tight tracking-tight text-center;
    }
}
