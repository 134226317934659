.container {
    @apply rounded-xl relative;
    video {
        @apply rounded-xl bg-green-xlight;
    }
}

.play {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white border-2 border-white rounded-full p-8 opacity-90 hover:opacity-100 cursor-pointer  w-12 h-12 hover:scale-105 transition-all hover:border-green-50;
    svg {
        @apply absolute left-[55%] top-1/2 -translate-x-1/2 -translate-y-1/2 hover:text-green-light w-full h-auto;
    }
    [data-video-playing="true"] & {
        display: none;
    }
}
