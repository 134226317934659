@import './maps';

// Font setup
$font-clearface-bold: 'Clearface-Bold';
$font-maisonneue-book: 'MaisonNeue-Book';
$font-maisonneue-demi: 'MaisonNeue-Demi';
$font-maisonneue-bold: 'MaisonNeue-Bold';
$font-maisonneuemono-bold: 'MaisonNeueMono-Bold';

$font-family-main: $font-maisonneue-book;
$font-family-headings: $font-clearface-bold;
$font-family-bold: $font-maisonneue-demi;
$font-family-bolder: $font-maisonneue-bold;

$font-size-base: 16px;

// Headings
$font-size-h1: 3rem;
$font-size-h2: 2.5rem;
$font-size-h3: 2rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;

// Helpers
$font-xsmall: map-get(
    $map: $fontSize,
    $key: xs,
);
$font-small: map-get(
    $map: $fontSize,
    $key: sm,
);
$font-base: map-get(
    $map: $fontSize,
    $key: base,
);
$font-regular: map-get(
    $map: $fontSize,
    $key: regular,
);
$font-medium: map-get(
    $map: $fontSize,
    $key: md,
);
$font-large: map-get(
    $map: $fontSize,
    $key: lg,
);
$font-xlarge: map-get(
    $map: $fontSize,
    $key: xl,
);
$font-huge: map-get(
    $map: $fontSize,
    $key: xxl,
);
$font-display1: map-get(
    $map: $fontSize,
    $key: xxxl,
);
$font-display2: map-get(
    $map: $fontSize,
    $key: xxxxl,
);
$font-display3: map-get(
    $map: $fontSize,
    $key: xxxxxl,
);

// Helper map

$font-helper-map: (
    xsmall: $font-xsmall,
    small: $font-small,
    regular: $font-regular,
    medium: $font-medium,
    large: $font-large,
    xlarge: $font-xlarge,
    display1: $font-display1,
    display2: $font-display2,
    display3: $font-display3,
);
