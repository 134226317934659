@import '../../scss/variables';
@import '../../scss/utilities/breakpoints';

.page {
    // Structure classes, define here exactly the spacing in bulk.
    // Values are 1 = 0.25. Most common will be 4 which is equivalent to 1rem.
    // Example: py-2 == 0.5rem, py-4 == 1rem,  py-8 == 2rem and so on.
    .info {
        @apply px-4 md:px-0;
    }

    .hairlines,
    .facts,
    .stories,
    .what-to-expect,
    .how-it-works,
    .aussie-doctors,
    .free-consult {
        @apply md:px-0 py-8 lg:py-16;
    }

    .treatments {
        @apply pt-8 md:py-8 lg:pt-16;
    }

    ::-webkit-scrollbar {
        cursor: pointer;
        border: 1px solid transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 1rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $green;
        border-radius: 0.5rem;

        &:hover {
            background: $green-dark;
        }
    }
    .reviews {
        ::-webkit-scrollbar-thumb {
            background: $white;
        }
    }
}

// Background assignments in bulk
.hairlines,
.treatments,
.stories,
.aussie-doctors,
.free-consult {
    @apply bg-green-light;
}

.info,
.facts,
.how-it-works,
.faq {
    @apply bg-sand-light;
}

.reviews {
    @apply bg-green;
}

.companies {
    @apply bg-green-light text-white;
}

// Specific styles
$card-section-name-background: #f5f5f5;
$card-section-name-color: $green-dark;
$card-section-item-color: #6b6b6b;
$card-section-item-border-color: #e4e4e4;
$card-border-radius: 10px;

.hairlines {
    padding: 3rem 0;
    [data-heading] {
        @apply mb-12 mx-auto lg:mx-0;
    }
}

.upsell {
    background-color: $green-light;
    @include md-up {
        background: linear-gradient(90deg, $white 50%, $sand-light 50%);
    }
    .unsure,
    .guarantees,
    .starter {
        @apply w-full;
    }

    .guarantees {
        @apply py-8 px-4 bg-white;
        [data-heading] {
            @apply mb-4;
        }
        [data-accordion] {
            @apply sm:max-w-md mx-auto;
        }
    }
    .unsure,
    .starter {
        @apply md:w-1/2 md:h-full;
    }

    .unsure {
        @apply bg-green-light md:bg-sand-light md:order-last px-4;
        [data-button] {
            @apply px-4 mt-4;
        }
    }
    .guarantees {
        @apply md:hidden;
    }

    .starter {
        @apply bg-sand-light md:bg-white;
    }
}

.info-bottom {
    @include md-up {
        background: linear-gradient(90deg, $sand-light 50%, $white 50%);
    }
    .faq,
    .learn-more {
        @apply py-8 px-4;
        @apply w-full md:w-1/2;
    }
    .learn-more {
        @apply lg:px-8
    }
}

.free-consult {
    @apply text-center;

    button {
        @apply inline-block mx-auto;
    }
}

.doctor-card {
    @apply pt-12 md:pt-4;
    .card-mobile {
        position: relative;
        padding-top: 4rem;
        border-radius: 1rem;
    }

    .doctor-thumbnail {
        @apply bg-white;
        border-radius: 100%;
        overflow: hidden;
        width: 6rem;
        height: 6rem;
        position: absolute;
        top: -3rem;
        left: 50%;
        transform: translateX(-50%);
        border: 4px solid $white;
        picture {
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
    .doctor-quote {
        @apply py-8 px-2 text-center;
        line-height: 2;
        font-size: $font-regular;
        @include xl-up {
            font-size: $font-medium;
        }
    }
}

$facts-headline-border-color: #e4e4e4;
.fact-info {
    .fact,
    .causes,
    .questions {
        @apply w-full bg-white md:bg-[transparent] p-4 mb-4;
        border-radius: 1rem;
        [data-heading] {
            border-bottom: 2px solid $facts-headline-border-color;
            padding-bottom: 0.25rem;
            margin-bottom: 1rem;
            > * {
                @apply text-left;
                font-family: $font-family-bold;
                margin: 0;
                font-size: $font-regular;
                @include lg-up {
                    font-size: $font-medium;
                }
            }
        }
    }
    .fact {
        p {
            margin-left: 0.5rem;
            small {
                color: #6b6b6b;
            }
        }
    }

    .causes-list {
        border-bottom: 1px solid $facts-headline-border-color;
        li {
            text-align: center;
            justify-content: center;
            img {
                width: 2rem;
                height: auto;
            }
            &:first-child {
                img {
                    width: 3rem;
                }
            }
            &:last-child {
                img {
                    width: 2.75rem;
                }
            }
        }
    }
    .info-percentage-wrapper {
        border-bottom: 1px solid $facts-headline-border-color;
        padding-bottom: 1rem;
    }
    .info-percentage {
        p {
            a {
                display: block;
                font-size: $font-xsmall;
                color: #6b6b6b;
                text-decoration: underline;
            }
        }
    }
    [data-accordion] {
        button {
            border-bottom: 1px solid $facts-headline-border-color;
        }
    }
}
