@import 'src/scss/utilities/breakpoints';

.therapist {
    header {
        aspect-ratio: 222 / 137;
        @include lg-up {
            aspect-ratio: 291 / 241;
            
        }
    }
}

.therapist-modal--wrapper[data-modal-content] {
    @apply p-0;
}
.therapist-modal--content {
    
    width: calc(100vw - 2rem);
    max-width: $lg;
    li {
        @apply mb-1 last:mb-0;
    }
}