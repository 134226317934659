$animationSpeed: 40s;

// Animation
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-280px * var(--as-seen-in-count)));
    }
}

$logoWidth: 280px;
$logoWidthNegative: -#{$logoWidth};
$logoHeight: 64px;

.as-seen-in {
    padding: 2rem auto;
    overflow: hidden;
    position: relative;
}
.as-seen-in__track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc($logoWidth * var(--as-seen-in-count));
}

.as-seen-in__item {
    height: $logoHeight;
    width: $logoWidth;
    flex-shrink: 0;
}
