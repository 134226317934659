@import 'src/scss/variables';

.reviews-wrapper {
    @apply mb-4;
    footer {
        font-family: $font-family-bold;
    }
    .reviews-wrapper--scrollbar-track {

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $green-dark;
            &:hover {
                background: $white;
            }
        }
    }
}

.card-image-wrapper {
  img {
    width: 50px !important;
    height: 50px !important;
    @apply rounded-full object-cover;
  }
}
