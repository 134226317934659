@import 'src/scss/variables';

.doctor-card {
    [data-heading] {
        > * {
            font-size: $font-medium;
            font-family: $font-family-bold;
        }
    }
    > section span {
        font-size: $font-regular;
        font-family: $font-family-bold;
        color: #6b6b6b;
    }
}

.wrapper {
    [data-cards-count='1'] {
        justify-content: center;
    }
}

.card-single {
    @apply w-full sm:w-[90%] lg:w-2/3;
    :global(.card) {
        @apply h-auto;
        header {
            aspect-ratio: 386 / 305;
            picture {
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
