@import "../../src/scss/utilities/breakpoints";
@import "../../src/scss/variables";

.doctor-review__title {
    color: $grey-400;
    font-size: $font-small;
}

.doctor-review__profile {
    @apply flex justify-center items-center mt-1;

    span {
        text-decoration: underline;
        font-family: $font-maisonneue-demi;
        font-size: $font-small;
    }
}
