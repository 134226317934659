@import '../../../scss/variables/colours';
@import '../../../scss/variables/typography';
@import '../../../scss/utilities/mixins';

.footer {
    background-color: $green-dark;
    color: $sand-light;
    min-height: 25vh;
}

.copyright {
    margin-bottom: 1rem;
    span,
    p {
        display: block;
    }

    span {
        margin-bottom: 1rem;
    }
    p {
        font-size: $font-small;
        opacity: 0.7;
    }
}

.navigation {
    p {
        text-transform: uppercase;
        font-size: $font-regular;
        font-family: $font-family-bold;
        color: $green;
        letter-spacing: 3px;
    }
    ul {
        padding-right: 0.25rem;
    }
    a {
        @apply pb-4 xl:pb-2;
        &:hover {
            color: $green;
        }
    }
}

.footer-logos {
    margin-top: 1rem;
}
