.wrapper {
    :global(.mosh-list) {
        ul {
            margin-left: 0;
        }
        li {
            display: grid;
            grid-template-columns: 1rem 1fr;
            gap: 0.5rem;
            align-items: start;
        }
    }

    [data-accordion-button] {
        img {
            @apply max-w-[60px] lg:max-w-none;
        }
    }
}