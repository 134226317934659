@import '../../scss/variables';
@import '../../scss/utilities/breakpoints';

$card-section-name-background: $white;
$card-section-name-color: #8fa8a7;

.default {
    background: $card-section-name-background;
    text-align: left;
    @apply rounded-2xl border border-grey-200;

    header {
        padding: 1rem 1rem 0 1rem;

        picture {
            width: 100%;
            height: auto;
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
        }
    }

    section {
        padding: 1rem;
    }

    .specialty {
        color: $card-section-name-color;
        font-family: $font-family-bold;
        text-transform: uppercase;
        $font-size: $font-xsmall;
        letter-spacing: 2px;
        margin-bottom: 0.5rem;
        display: block;
    }

    .name {
        font-family: $font-family-bold;
    }

    &[data-card='generic'] {
        header {
            padding-bottom: 91.73913043478261%; // fallback rule for non-supported aspect-ratio (height / width * 100%);
            aspect-ratio: 134 / 123; // this overrides the rule above when supported.
            overflow: hidden;
            position: relative;
            width: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 0.5rem 0.5rem 0 0;
            }
        }

        section {
            font-family: $font-family-bold;

            [data-heading]>* {
                font-family: $font-family-bold;
                font-size: 1.125rem;
                letter-spacing: normal;
            }

            span {
                color: $grey-400;
            }
        }
    }

    &[data-card='doctor-with-info'] {
        .card-title p {
            @apply text-regular;
        }

        .card-subtitle {
            @apply mb-4 block font-bolder text-grey-400;
        }
    }
}

.content {
    @apply w-full;
}

.card-before-after {
    @apply flex flex-col rounded-xl border border-white;

    header {
        @apply flex flex-grow;

        img {
            @apply w-1/2 basis-1/2 rounded-none;
        }

        picture {
            @apply flex overflow-hidden rounded-md;
        }
    }

    section {
        @apply mt-auto;
    }

    .card-before-after-content {
        @apply py-2 font-bolder;
    }
}

// A new beginning... the generic card: it does what you want, when you want it and you manage it at a wrapper level

.generic {
    @apply h-full overflow-hidden rounded-2xl border border-grey-200 bg-white;
    @apply relative flex flex-col;

    header {
        @apply mb-2 w-full overflow-hidden;
        aspect-ratio: 704/406;

        &.split {
            @apply mb-0;

            picture {
                min-height: 200px;
                @apply flex h-full flex-nowrap;

                img {
                    @apply h-full w-1/2 rounded-none object-cover object-center;
                }
            }
        }

        img {
            @apply max-h-full w-full rounded-lg object-cover;
        }
    }

    section {
        @apply flex-1;
    }

    footer {}
}

// Styles for Blog Post card, used in: BlogRelatedPosts and more
.blog-post-wrapper {
    @apply w-[80%] shrink-0;

    @include md-up {
        @apply w-1/2 shrink;
    }
}

.blog-post {
    @apply p-4;
}

// Card Product for CMS

.product {
    @apply flex w-full flex-wrap rounded-xl border-2 border-green bg-white p-4;

    @include md-up {
        @apply p-6;
    }

    .top {
        @apply mb-8 flex w-full flex-nowrap items-center justify-between;

        .badge {
            @apply h-9 rounded-full bg-green-light py-2 px-4 font-demi text-xs uppercase tracking-widest text-green-dark;

            @include md-up {
                @apply text-sm;
            }
        }

        .icon {}
    }

    .name {
        @apply w-full;

        [data-heading] {
            >* {}
        }
    }

    .section {
        @apply flex w-full flex-wrap space-y-4;

        // @apply md:flex-nowrap md:space-x-4 md:items-center;
        .image,
        .content {
            @apply w-full;
            // @apply md:w-1/2;
        }

        .image {
            @apply text-center;

            img {
                max-height: 120px;
                width: auto;
                margin: 0 auto;

                @include md-up {
                    max-height: 200px;
                }
            }

            .active-ingredients {
                @apply font-demi;
            }
        }

        .content {
            .usp {
                @apply mb-4 w-full text-center;

                span {
                    @apply mb-4 inline-flex flex-nowrap items-center rounded-full border border-green-dark py-2 pl-2 pr-4 font-demi text-xs text-base;

                    @include md-up {
                        @apply mx-auto;
                    }

                    svg {
                        @apply mr-2 h-2 w-2 rounded-full border border-green-dark p-1;
                    }
                }
            }

            .slices {
                @apply mb-4;
            }

            .bottom {
                @apply flex flex-wrap items-center justify-center space-x-4;

                .price {
                    @apply mb-4 w-full text-center font-demi text-md;
                }

                .cta {}
            }
        }
    }
}

.product:not(:last-child) {
    @apply mb-0;

    @include md-up {
        @apply mb-4;
    }
}
