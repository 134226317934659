@import "../../src/scss/utilities/breakpoints";
@import "../../src/scss/variables/index";

.blog-hero {
    @apply flex flex-col text-center items-center px-4 mt-6 lg:mt-8;
    position: relative;
    object-fit: cover;

    h1 {
        @apply mt-4 md:mt-6;
        font-size: $font-large;
        line-height: normal;
    }
}

.blog-hero__img {
    @apply rounded-2xl w-full h-[10rem] object-cover md:h-[17.5rem];
    position: relative;
    img {
        object-fit: cover;
        border-radius: 16px;;
    }
}

.blog-hero__description {
    font-size: $font-small;
    color: $grey-400;
    margin-top: 0.5rem;
}
