@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.icon-large-square {
    max-height: 100px;
    height: 100%;
    width: auto;
}

.icon-large-rectangle {
    max-height: 100px;
    height: 100%;
    width: auto;
}

.icon-xsmall-square {
    max-height: 60px;
    height: 100%;
    width: auto;

    @include md-up {
        max-height: 80px;
        height: 80px;
    }
}

.icon-small-square {
    max-height: 70px;
    height: 100%;
    width: auto;
}

.icon-small-rectangle {
    max-height: 50px;
    height: 100%;
    width: auto;
}

.columns {
    // @apply py-8 grid grid-cols-1 gap-y-8 md:gap-y-0 md:gap-x-8 last:pb-0;
}

.column {
    @apply w-full relative text-center;
}

.how-it-works {
    .columns {
        @apply md:gap-x-16;
    }
    .column {
        @apply relative pb-8 text-left md:text-center;
        > div {
            @apply justify-start;
            @apply md:justify-center md:px-8;
            @apply lg:px-0;
        }
        > p {
            @apply text-left md:text-center;
        }
        &:first-child {
            .arrow {
                transform: rotate(-116deg) scaleX(-1);
                right: 0;
                bottom: -2rem;
                @include md-up {
                    transform: rotate(38deg);
                    right: -3.75rem;
                    bottom: 4rem;
                }
            }
        }
        &:nth-child(2) {
            @apply pl-16 md:pl-0 md:pt-12;
            .arrow {
                left: 0;
                transform: rotate(120deg);
                @include md-up {
                    left: auto;
                    right: -3.75rem;
                    bottom: auto;
                    transform: scaleX(-1) rotate(233deg);
                    top: 5rem;
                }
            }
        }
    }
    .arrow {
        @apply h-auto w-16 absolute bottom-0;
        path {
            @apply fill-green-dark;
        }
    }
}
