@import '../../scss/utilities/breakpoints';
@import '../../scss/variables';

.section {
    img {
        width: 100%;
    }

    @include md-up {
        position: relative;
        picture {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.content {
    @apply py-8;
    @apply md:py-12 md:py-16;
    @apply lg:py-24;
    @apply xl:max-w-[48%] xl:py-32;
    @apply xxl:py-36;
    [data-heading] {
        @apply mb-4;
        > * {
            font-size: clamp(2.25rem, 3.5vw, $font-huge);
        }
    }
    .description,
    .children {
        @apply mb-4;
        @include xxl-up {
            font-size: $font-medium;
        }
    }

    .smallDescription {
        @apply font-bolder;
    }
    [data-button] {
        @apply mb-0;
    }
}

.children {
    @apply mb-4 w-full;
}
