@import '../variables/maps';

// Breakpoint variables
$sm: map-get(
    $map: $breakpoints,
    $key: sm,
);
$md: map-get(
    $map: $breakpoints,
    $key: md,
); // 768px
$lg: map-get(
    $map: $breakpoints,
    $key: lg,
); // 992px
$xl: map-get(
    $map: $breakpoints,
    $key: xl,
); // 1200px
$xxl: map-get(
    $map: $breakpoints,
    $key: xxl,
); // 1400px
$xxxl: 1600px; // 1600px

$xxxxl: 1800px; // 1800px
$xxxxxl: 1900px;
$phone-max: 575px;
$sm-max: 767px;
$md-max: 991px;
$lg-max: 1199px;
$xl-max: 1399px;

@mixin sm-up {
    @media (min-width: $sm) {
        @content;
    }
}
@mixin md-up {
    @media (min-width: $md) {
        @content;
    }
}
@mixin lg-up {
    @media (min-width: $lg) {
        @content;
    }
}
@mixin xl-up {
    @media (min-width: $xl) {
        @content;
    }
}
@mixin xxl-up {
    @media (min-width: $xxl) {
        @content;
    }
}
@mixin xxxl-up {
    @media (min-width: $xxxl) {
        @content;
    }
}
@mixin xxxxl-up {
    @media (min-width: $xxxxl) {
        @content;
    }
}

@mixin xxxxxl-up {
    @media (min-width: $xxxxxl) {
        @content;
    }
}

@mixin phone-only {
    @media (max-width: 599px) {
        @content;
    }
}
@mixin between-zero-md {
    @media (min-width: 0) and (max-width: $sm-max) {
        @content;
    }
}
@mixin between-sm-md {
    @media (min-width: $sm) and (max-width: $sm-max) {
        @content;
    }
}
@mixin between-zero-lg {
    @media (min-width: 0) and (max-width: $md-max) {
        @content;
    }
}
@mixin between-md-lg {
    @media (min-width: $md) and (max-width: $md-max) {
        @content;
    }
}
@mixin between-lg-xl {
    @media (min-width: $lg) and (max-width: $lg-max) {
        @content;
    }
}
@mixin between-xl-xxl {
    @media (min-width: $xl) and (max-width: $xl-max) {
        @content;
    }
}

// Special breakpoints
@mixin ipad-only {
    @supports (-webkit-touch-callout: none) {
        @media only screen and (device-width: 768px) {
            @content;
        }
    }
}

@mixin ipad-portrait-only {
    @supports (-webkit-touch-callout: none) {
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
            @content;
        }
    }
}

@mixin ipad-landscape-only {
    @supports (-webkit-touch-callout: none) {
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
            @content;
        }
    }
}

@mixin iphone-only {
    /* Portrait and Landscape */
    /* ----------- iPhone 4 and 4S ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
    /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

    /* Portrait and Landscape */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
    /* ----------- iPhone 6, 6S, 7 and 8 ----------- */

    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }

    /* ----------- iPhone 6+, 7+ and 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
        @content;
    }

    /* ----------- iPhone X ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        @content;
    }
}

@mixin iphone-landscape-only {
    /* ----------- iPhone 4 and 4S ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        @content;
    }
    /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        @content;
    }
    /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        @content;
    }
    /* ----------- iPhone 6+, 7+ and 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
        @content;
    }
    /* ----------- iPhone X ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
        @content;
    }
}

@mixin iphone-portrait-only {
    /* ----------- iPhone 4 and 4S ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        @content;
    }
    /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        @content;
    }
    /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        @content;
    }
    /* ----------- iPhone 6+, 7+ and 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
        @content;
    }
    /* ----------- iPhone X ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
        @content;
    }
}

@mixin screen-landscape-small {
    @media (orientation: landscape) and (max-height: 800px){
        @content;
    }
}
