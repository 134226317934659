@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.supporting-our-boys--wrapper {
    @apply flex px-4 md:px-[7.5rem] text-center lg:text-left;
    .wrapper {
        @apply w-full flex gap-y-8 flex-wrap items-center;
        @include lg-up {
            @apply gap-y-0 gap-x-16;
        }
    }
    .header-item {
        @apply flex order-last w-full;
        @include lg-up {
            @apply w-[50%] lg:order-first;
        }
    }
    .header-title {
        @apply mb-6;
        h2 {
            @apply text-[2rem] lg:text-[3rem];
        }
    }
    .image-item {
        @apply mx-auto;
        @include lg-up {
            @apply w-[40%] lg:order-first;
        }
    }
    .supporting-our-boys--logo {
        @apply mx-auto;
        height: fit-content;
        img {
            margin: 0 auto;
        }
        @include between-zero-lg {
            @apply w-72 max-w-[21.5rem];
        }
        @include between-md-lg {
            display: flex;
            align-items: center;
            height: 100%;
        }
        @include ipad-portrait-only {
            height: auto;
        }
    }
}