@import '../../../scss/variables';
@import '../../../scss/utilities/breakpoints';

$navigation-item-box-shadow: 0 0 0.5rem rgb(0 0 0 / 10%);
$navigation-link-letter-spacing: 0.15rem;

.main-navigation {
    display: flex;
    background-color: $white;
    align-items: center;
    > ul {
        display: flex;
        margin-left: 1rem;
        @include md-up {
            margin-left: 2rem;
        }
        .list-item {
            > a,
            > button {
                letter-spacing: $navigation-link-letter-spacing;
                font-size: clamp($font-xsmall, 1vw, 1rem);
                @apply flex flex-nowrap space-x-2 items-center font-bolder uppercase;
                > [data-icon] {
                    margin-left: 0.5rem;
                    transition: transform 200ms;
                    transform-origin: center;
                }
                @include phone-only {
                    padding: 0.5rem 0.75rem;
                    border: 2px solid $green-dark;
                    border-radius: 0.25rem;
                }
                @include sm-up {
                    margin-right: 1rem;
                    &:hover {
                        color: $green;
                    }
                }
                @include md-up {
                    margin-right: 2rem;
                }
            }
            &[data-navigation-open='true'] {
                > button {
                    color: $green;
                    border-color: $green;
                    [data-icon] {
                        transform: rotate(180deg);
                        color: $green;
                    }
                }
            }
        }
    }
}

.content {
    position: absolute;
    width: 100%;
    background-color: $green-light;
    transform: translate(-50%, -1px);
    left: 50%;
    bottom: 1px;
    z-index: -1;
    transition: all 200ms;
    padding: 1rem;
    opacity: 0;
    &[data-version='2'] {
        @apply bg-white;
    }
    [data-navigation-open='true'] & {
        transform: translate(-50%, 100%);
        opacity: 1;
        //@apply border-y-2 border-green-dark;
    }
    @include between-zero-md {
        height: calc(100vh - var(--header-height));
        overflow-y: scroll;
        padding-bottom: 8rem;
    }
    @include lg-up {
        padding: 1.5rem 0;
    }

    .items {
        @include md-up {
            padding-bottom: 0;
        }
    }

    .close {
        display: inline-block;
        margin: 0 auto;
        position: sticky;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100%;
        background-color: $green-dark;
        color: $white;
        box-shadow: $navigation-item-box-shadow;
        padding: 1rem;
        @include md-up {
            display: none;
        }
    }
}

// Layout only rules for items, item and children for the grid setup
.items:not(.items-tiles) {
    .item {
        margin-bottom: 1rem;
    }

    @include md-up {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        .item {
            margin-bottom: 0;
            &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }

            &:nth-child(2) {
                grid-area: 1 / 2 / 2 / 3;
                @include md-up {
                    grid-area: 2 / 1 / 3 / 2;
                }
            }

            &:nth-child(3) {
                grid-area: 1 / 3 / 2 / 4;
                @include md-up {
                    grid-area: 3 / 1 / 4 / 2;
                }
            }

            &:nth-child(4) {
                grid-area: 2 / 1 / 3 / 2;
                @include md-up {
                    grid-area: 1 / 2 / 2 / 3;
                }
            }

            &:nth-child(5) {
                grid-area: 2 / 2 / 3 / 3;
            }

            &:nth-child(6) {
                grid-area: 3 / 1 / 4 / 2;
                @include md-up {
                    grid-area: 3 / 2 / 4 / 3;
                }
            }

            &:nth-child(7) {
                grid-area: 3 / 2 / 4 / 3;
                @include md-up {
                    grid-area: 1 / 3 / 2 / 4;
                }
            }

            &:nth-child(8) {
                grid-area: 3 / 3 / 4 / 4;
                @include md-up {
                    grid-area: 2 / 3 / 3 / 4;
                }
            }
        }

        .item--last {
            grid-area: 3 / 3 / 4 / 4;
        }
    }
    .item--last {
        [data-version='0'] & {
            p {
                @apply text-center;
            }
        }
        [data-version='1'] & {
            @apply hidden md:flex md:flex-wrap md:justify-center;
        }
    }
}

.item {
    padding: 0.5rem;
    background-color: $white;
    color: $green-dark;
    border-radius: 1rem;
    transition: transform 100ms;
    font-family: $font-family-bold;
    text-transform: uppercase;
    border: 0.125rem solid transparent;
    cursor: pointer;
    box-shadow: $navigation-item-box-shadow;
    font-size: clamp(0.75rem, 0.5ch, 1rem);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    [data-version='2'] & {
        @apply rounded-lg shadow-sm border border-grey-200;
        @apply md:inline-flex pr-4 xl:flex;
        span {
            @apply w-full normal-case font-bolder tracking-normal leading-tight;
            @apply text-base md:text-xs ml-0;
        }
        svg {
            display: none;
        }
    }
    @include md-up {
        // padding: 1rem;
        font-size: clamp(0.75rem, 0.75ch, 1rem);
    }
    @include between-md-lg {
        padding: 0.5rem;
        font-size: 12px;
    }
    @include lg-up {
        font-size: 0.85rem;
    }
    @include xxl-up {
        font-size: 1rem;
    }
    &:is(a) {
        letter-spacing: $navigation-link-letter-spacing;
    }
    &:hover {
        transform: scale(1.01);
        border-color: $green;
        [data-icon] {
            color: $green-dark;
        }
    }

    img {
        width: 100%;
        max-width: 3rem;
        margin-right: 0.5rem;
        padding: 0.25rem 0;
        @include between-md-lg {
            padding: 0rem;
            max-width: 2.5rem;
        }
        @include xl-up {
            max-width: 4rem;
        }
    }
    [data-icon] {
        margin-left: auto;
        color: $grey;
    }
}
.items-tiles {
    @apply grid grid-cols-2 gap-4 mb-4 sm:grid-cols-3 lg:mb-8;
    @apply md:flex md:flex-nowrap md:space-x-4 md:gap-0;

    .item-tile {
        @apply w-full flex flex-wrap justify-center p-4 h-[7rem] rounded-lg m-0 self-center;
        @apply md:w-[12vw] md:h-[12vw];
        @apply lg:w-[10vw] lg:h-[10vw];
        @apply xl:w-[9vw] xl:h-[9vw];
        @apply xxl:w-[9vw] xxl:h-[9vw] xxl:max-w-[120px] xxl:max-h-[120px];
        img {
            @apply mx-auto mb-1;
            @apply block h-[2.75rem] w-auto;
            @apply md:h-[1.75rem];
            @apply lg:h-[3rem];
        }
        span {
            @apply text-base md:text-xs lg:text-sm ml-0 text-center;
        }
    }
    .item--last {
        [data-version='2'] & {
            @apply hidden lg:flex;
        }
    }
}

.item--last {
    @apply hidden;
    @apply lg:flex lg:flex-col lg:items-center;
    @apply xl:ml-4;
    p {
        @apply w-full mb-2 mt-auto;
    }
    [data-button] {
        margin-bottom: auto;
    }
    [data-version='0'] &,
    [data-version='1'] & {
        p {
            @apply text-center;
        }
    }
    [data-version="2"] & {
        p {
            @apply font-bolder;
        }
    }
}

.bottom {
    @apply flex flex-wrap items-start justify-between;
    @apply md:container md:grid md:grid-cols-2 md:gap-4;
    @apply lg:grid-cols-6;
    :global(.health-store) {
        @apply md:order-1;
        @apply lg:col-span-2 lg:pr-4;
    }
    :global(.popular-products) {
        @apply md:order-2;
        @apply lg:col-span-4;
    }
}

.health-store {
    @apply mb-4 md:mb-0;
    .health-store--button {
        span {
            @apply xl:text-base;
        }
    }
}
