@import 'src/scss/variables';

.wrapper {
    p {
        font-family: $font-family-bold;
    }
    [data-trustpilot="logo-star"] {
        margin-bottom: 5px;
    }
}

.on-hero {
    @apply flex flex-nowrap space-x-1 items-center mt-4;
    p {
        @apply mb-0 text-sm xl:text-base;
        strong {
            font-weight: normal;
            @apply font-demi;
        }
    }
    img {
        // Visual correction to align both the Trustpilot image and the content string.
        margin-top: -0.25rem;
    }

}