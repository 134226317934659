@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables';

.share__header {
    font-family: $font-maisonneue-demi;
    font-size: $font-small;
    margin-bottom: 0.5rem;
}
.share__buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 0.5rem;
    button {
        display: flex;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        align-items: center;
        justify-content: center;
        background-color: $green-dark !important;
    }
    svg {
        color: $white;
    }
}
