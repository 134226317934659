.section-wrapper {
  @apply flex flex-col;
  // @apply md:grid md:grid-rows-none md:grid-cols-[1.2fr_0.8fr] md:min-h-[512px];
  @apply md:flex-row;
}

.image-wrapper {
  @apply min-h-[240px] h-auto w-full relative order-1;
  @apply md:order-none md:w-[50%] lg:w-[60%] md:h-[527px];
  img, span {
    @apply w-full h-full;
  }
  img {
    @apply object-cover;
  }
}

.content-wrapper {
  @apply py-10 flex flex-col px-4 items-center justify-center md:items-start;
  @apply md:px-16 md:w-[50%];
  @apply lg:w-[40%];
  h2,p {
    @apply text-center;
    @apply md:text-left;
  }
}
