@import '../../scss/variables';
@import '../../scss/utilities/breakpoints';

.grid {
    @apply mx-auto flex lg:container;

    .item {
        @apply flex w-1/3 flex-wrap content-start pt-6 md:space-x-1 lg:flex-nowrap lg:items-center lg:space-x-2 lg:py-8;

        span {
            @include md-up {
                font-family: $font-family-bold;
            }
        }

        [data-has-icon] {
            @apply mx-auto mb-4 md:flex-none lg:mx-0;
            width: 4rem;
            height: 4rem;
            position: relative;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-height: calc(100% + 1rem);
            }
            @include lg-up {
                width: 6rem;
                height: 6rem;
            }
        }
        [data-has-icon] {
            pointer-events: none;
            &[data-icon-has-background] {
                border-radius: 50rem;
            }
        }
        .info {
            @apply text-center md:flex-auto lg:ml-4 lg:text-left;
            width: 100%;
            height: auto;
            @include md-up {
                height: 100%;
            }

            span {
                @apply mx-auto mb-4 block text-xs md:text-sm lg:mx-0 lg:text-base;
            }
            p {
                @apply hidden lg:block lg:font-normal;
                padding-right: 1rem;
            }
        }
    }
}
