@import 'src/scss/variables/components';

.title {
    @apply text-green-dark;
}
.items {
    @apply flex flex-col items-stretch justify-center self-stretch space-y-4;
    @apply md:flex-row md:space-x-4 md:space-y-0;

    .item {
        @apply flex flex-col items-start space-y-4 w-full p-4 text-left border border-grey-200 bg-white rounded-2xl;
        box-shadow: $box-shadow-md;

        .item-image-wrapper {
            @apply w-full;
            img {
                @apply w-full object-cover rounded-lg;
                height: 200px;
            }
        }
        .item-image-placeholder {
            @apply w-full bg-grey-200 rounded-lg;
            min-height: 200px;
        }

        .item-content {
            @apply space-y-3;
        }
        .item-footer {
            @apply flex flex-col self-stretch items-start;
            @apply md:h-full md:justify-end;
            .item-cta {
                @apply sm:w-full;
            }
        }
    }
    &.items-count-2 .item { @apply md:w-1/2 }
    &.items-count-3 .item { @apply md:w-1/3 }
    &.items-count-4 .item { @apply md:w-1/4 }
}