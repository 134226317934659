@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables';

.card {
    // Generic
    @apply flex justify-between md:flex-row-reverse;

    .card__content {
        @apply flex flex-col items-start space-y-8 md:w-1/2;
    }
    .card__list {
        @apply flex flex-col space-y-2;
        li {
            font-size: medium;

            svg {
                margin-right: 0.5rem;
            }
        }
    }
    .card__img {
        @apply relative object-cover md:w-1/2;

        img {
            @apply rounded-2xl object-cover;
        }
    }

    @apply flex-col rounded-2xl md:flex-row-reverse;
    background: $sand-light;

    .card__content {
        @apply p-6 pt-0 md:p-7;
    }
    .card__img {
        @apply mb-6 h-[13rem] md:mb-0 md:rounded-l-none;

        img {
            @apply md:rounded-l-none;
        }
        @include md-up {
            height: inherit;
        }
    }
}
