.hidden-rows {
    -webkit-transition-property: max-height, visibility;
    transition-property: max-height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    &.open-animation {
        -webkit-transition-duration: 1.7s;
        transition-duration: 1.7s;
    }
    @apply overflow-hidden;
}

.column-button-group {
    @apply relative mx-auto mb-10 flex w-full max-w-[20rem] items-center justify-center rounded-full bg-green-light font-demi;
    @apply md:hidden;
    @apply before:absolute before:top-0 before:block before:h-[35px] before:w-[50%] before:rounded-full before:bg-green-dark before:transition-all;
    button {
        @apply z-[2] w-[50%] rounded-full py-2 text-center text-sm uppercase tracking-widest transition-all;
    }
}

.expand-button {
    @apply mx-auto mt-4 flex items-center gap-3;
    @apply md:mt-5 md:px-4 md:py-3 md:text-regular;
}
