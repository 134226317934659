@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables';

.footer-banner {
    @apply mx-auto flex flex-col items-center px-4 py-10;
    @apply md:px-8 md:py-16 lg:py-20;
}

.footer-banner__narrow {
    @apply mx-auto flex flex-col items-center justify-center px-4 py-4 text-center;
    @apply md:flex-row md:items-end md:space-x-6 md:text-left;
}

.footer-banner__sticky {
    position: sticky;
    bottom: 0;
    z-index: 1000;
}

.footer-banner__icon {
    @apply mb-8 h-24;
    @apply md:mb-4 md:h-32;

    img {
        height: inherit;
        object-fit: contain;
    }
}

.footer-banner__bg {
    @apply flex h-80 flex-col items-center justify-center bg-center px-4 lg:h-[26rem];
}
