@import 'src/scss/utilities/breakpoints';

.card {
    @apply bg-white p-4 text-center mt-20 rounded-2xl shadow-sm mb-4 max-w-sm mx-auto;
    @apply lg:mt-20;
}

.card--thumbnail {
    @apply mx-auto w-32 h-32 object-cover -mt-20 bg-green-light rounded-full border-8 border-white mb-4;
    @apply lg:w-32 lg:h-32;
}

.card--name {
    @apply text-regular text-green-dark font-demi;
}
.card--title {
    @apply text-grey-400 text-sm;
}
.card--description {
    @apply pt-4 text-sm px-2;
}

.box {
    @apply bg-white p-4 rounded-xl shadow-sm mb-4;
    :global(.heading) {
        &:after {
            content: '';
            @apply w-6 border-t-2 border-t-green-dark block mt-2 mb-4;
        }
        > * {
            @apply text-regular tracking-tight;
        }
    }
}

.percentage {
    @apply max-w-sm mx-auto text-center md:bg-sand-light md:shadow-none;
}

.progress-bar {
    @apply border-4 rounded-lg border-green-dark mb-4;
    @apply md:max-w-[80%] md:mx-auto;
    span {
        @apply bg-green-dark border-green-dark font-heading text-lg pt-1 tracking-tight;
        @include phone-only {
            @apply justify-start text-lg pt-1 pl-2;
        }
    }
}

.boxes {
    @apply flex flex-wrap lg:max-w-2xl lg:mx-auto;
    .box1,
    .box2,
    .box4 {
        @apply w-full md:w-1/2;
        > p {
            // @apply text-sm;
        }
    }
    .box3 {
        @apply w-full;
    }
    .cta-box {
        @apply w-full md:w-1/2 text-center;
        @apply flex items-center justify-center flex-wrap;
        p {
            @apply mt-auto text-center w-full mb-4 xl:px-8;
        }
        a {
            @apply mb-auto;
        }
    }
}

.box-percentages {
    @apply flex flex-wrap sm:flex-nowrap sm:space-x-4 sm:items-center py-4 sm:py-0;
    > div {
        @apply w-full sm:w-1/2;
        @apply flex items-center space-x-2;
        p {
            @apply pr-4;
        }
    }
}

.ring {
    :global(.progress-ring--stroke) {
        @apply stroke-grey-200;
    }
    :global(.progress-ring--circle) {
        @apply stroke-green;
    }
}

.box-mixed {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-x-4;
    .box-mixed--item {
        @apply flex flex-nowrap space-x-4 items-center mb-4;
    }
    .stat-box {
        @apply inline-flex flex-nowrap space-x-1 min-w-[110px] justify-center;
    }
    p {
        @apply text-sm md:text-base;
    }
}
.references {
    @apply pt-8 px-4 text-xs lg:max-w-2xl lg:mx-auto;
    > li,
    a {
        @apply text-grey-400 hover:text-green-dark mb-3 md:mb-1;
    }
    a {
        line-break: anywhere;
    }
}
