@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.seo-content {
    [data-accordion] {
        button {
            padding-left: 1rem;
            padding-right: 1rem;
            font-family: $font-family-bold;
            svg {
                margin-left: 1rem;
            }
            @include md-up {
                justify-content: center;
            }
        }
        [data-accordion-content-expanded] {
            padding: 0 1rem;
            overflow: hidden;
            max-width: $md;
            margin: 0 auto;
        }
        [data-accordion-content-expanded="true"] {
            padding: 2rem 1rem;
        }
    }
    [data-heading] {
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: block;
        &:first-child {
            margin-top: 0;
        }
    }
}